@import url("https://fonts.googleapis.com/css?family=Muli:300,400,600,700,800");

.logo {
  font-family: "Muli", sans-serif;
  font-size: 30px;
  font-weight: 300;
  color: #1bbfbb !important;
}

.login-form > .ant-card > .ant-card-body {
  padding: 5px 24px !important;
}

.bg-login {
  width: 100%;
  position: relative;
  height: 100%;
}

.container-login {
  z-index: 1;
}

.bg-login::before {
  content: " ";
  display: block;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  opacity: 0.2;
  background-image: url("./assets/background-login.jpeg");
  background-repeat: no-repeat;

  -ms-background-size: cover;
  -o-background-size: cover;
  -moz-background-size: cover;
  -webkit-background-size: cover;
  background-size: cover;
}

.logo-sub-title {
  font-size: 18px;
  color: rgba(0, 0, 0, 0.65);
  font-weight: 400;
}

.logo-login {
  font-size: 20px;
}

body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.ant-layout-header {
  background: rgb(255, 255, 255);
  -webkit-box-shadow: 0 2px 8px #f0f1f2;
  box-shadow: 0 2px 8px #f0f1f2;
  padding: 0 20px;
  border: 1px solid #e8e8e8;
}

.main-wrapper {
  padding: 40px 0 0;
}

.ant-modal-mask{
  z-index: 565565 !important;
}
.ant-modal-wrap{
  z-index: 999999 !important;
}

.item {
  color: white;
  cursor: pointer;
  height: 200px;
  margin: 20px;
  position: absolute; /* Required by Muuri */
  width: 50%;
}

.muuri-item-dragging {
  z-index: 3; /* Required by Muuri */
}

.muuri-item-releasing {
  z-index: 2; /* Required by Muuri */
}

.muuri-item-hidden {
  z-index: 0; /* Required by Muuri */
}

.box1 {
  background-color: orange; /* Go */
}

.box2 {
  background-color: blue; /* Gators */
}

.highcharts-point {
  cursor: pointer !important
}

/* Carousel */

.slick-dots li button:before {
  font-size: 8px !important;
}

.slick-prev:before,
.slick-next:before {
  font-size: 40px !important;
  font-weight: 800 !important;
  opacity: 1 !important;
}

.slick-prev:hover,
.slick-next:hover {
  opacity: 0.7 !important;
}

.slick-prev {
  left: -45px !important;
}

.slick-next{
  right: -45px !important;
}

.slick-prev, .slick-next{
  width: 40px !important;
  height: 40px !important;
}

.slick-prev, .slick-nex{
  width: 40px !important;
  height: 40px !important;
}

/* MENU */

.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
  background-color: white !important;
}

.ant-menu:not(.ant-menu-item-selected) {
  color: #858ea9 !important;
}

.ant-menu-vertical .ant-menu-item:after,
.ant-menu-vertical-left .ant-menu-item:after,
.ant-menu-vertical-right .ant-menu-item:after,
.ant-menu-inline .ant-menu-item:after {
  left: 0 !important;
  border-left: 10px solid #1bbfbb !important;
  border-right: none !important;
}


.ant-menu-vertical .ant-menu-item:not(:last-child), .ant-menu-vertical-left .ant-menu-item:not(:last-child), .ant-menu-vertical-right .ant-menu-item:not(:last-child), .ant-menu-inline .ant-menu-item:not(:last-child){
  margin-bottom: 0px !important; 
}

.ant-menu-vertical > .ant-menu-item, .ant-menu-vertical-left > .ant-menu-item, .ant-menu-vertical-right > .ant-menu-item, .ant-menu-inline > .ant-menu-item, .ant-menu-vertical > .ant-menu-submenu > .ant-menu-submenu-title, .ant-menu-vertical-left > .ant-menu-submenu > .ant-menu-submenu-title, .ant-menu-vertical-right > .ant-menu-submenu > .ant-menu-submenu-title, .ant-menu-inline > .ant-menu-submenu > .ant-menu-submenu-title{
  line-height: 45px !important;
  height: 45px !important;
}

.ant-menu-item > a {
  font-size: 17px !important;
  letter-spacing: 0.5px !important;
  font-weight: 400;
  color: #7c8595 !important;
}

.ant-menu-item-selected > a,
.ant-menu-item-selected > a:hover {
  color: #343f5c !important;
  font-weight: 600;
}

.ant-menu-item:hover,
.ant-menu-item-active,
.ant-menu:not(.ant-menu-inline) .ant-menu-submenu-open,
.ant-menu-submenu-active,
.ant-menu-submenu-title:hover {
  color: #1bbfbb !important;
}

.menu-indicator {
  margin-left: 24px;
  padding-bottom: 10px;
  font-size: 20px !important;
  letter-spacing: 0.5px !important;
  font-weight: 700;
  color: #343f5c !important;
}

.menu-indicator button {
  float: right;
  margin-right: 8px;
  margin-top: 8px;
  border: none !important;
  font-size: 20px !important;
  color: #7c8595 !important;
}

.menu-indicator button:hover {
  color: #343f5c !important;
}

/* DATA PICKER */

.ant-calendar-selected-date .ant-calendar-date,
.ant-calendar-selected-start-date .ant-calendar-date,
.ant-calendar-selected-end-date .ant-calendar-date {
  background: #1bbfbb !important;
}

.ant-calendar-time-picker-btn{
  display: none !important;
}

.ant-calendar-footer {
  line-height: 48px !important;
}
.ant-calendar-footer-extra.ant-tag {
  border-radius: 14px;
}

.ant-tag-blue {
  color: #1bbfbb !important;
  background: #ddf6f6 !important;
  font-size: 14px;
  font-weight: 600;
  border: 0.5px #ddf6f6 solid !important;
}

.ant-tag-blue:hover {
  border: 0.5px #1bbfbb solid !important;
}

.ant-tag-red {
  color: #343f5c;
  background: rgba(240, 115, 150, 0.2);
  border-color: transparent !important;
}

.ant-tag-yellow {
  color: #343f5c;
  background: rgba(255, 205, 86, 0.2);
  border-color: transparent !important;
}

.ant-tag-green {
  color: #343f5c;
  background: rgba(98, 209, 113, 0.2);
  border-color: transparent !important;
}

.ant-tag-geekblue {
  color: #343f5c;
  background: rgba(54, 162, 235, 0.2);
  border-color: transparent !important;
}


.ant-calendar-picker-input:hover {
  border-color: #1bbfbb !important;
}
.ant-progress-status-success .ant-progress-circle-path {
  color: rgb(122, 212, 204) !important;
}

.ant-progress-status-success .ant-progress-text {
  color: rgb(122, 212, 204) !important;
}

.ant-progress-circle-path {
  stroke: rgb(122, 212, 204) !important;
}
.ant-calendar-picker:focus .ant-calendar-picker-input:not(.ant-input-disabled) {
  border-color: #1bbfbb !important;
}

.ant-calendar-today .ant-calendar-date {
  font-weight: normal;
}

.ant-calendar-range-picker-input {
  width: unset !important;
}
/* RADIO BUTTOM*/

.ant-radio-button-wrapper-checked {
  color: #1bbfbb !important;
  border-color: #1bbfbb !important;
  box-shadow: -1px 0 0 0 #1bbfbb !important;
}

.ant-radio-button-wrapper-checked:first-child {
  border-color: #1bbfbb;
}

.ant-radio-button-wrapper:hover {
  color: #1bbfbb !important;
}

.title-category{
  color: #343f5c;
  font-size: 12px;
}

.ant-statistic-title{
  color: #343f5c !important;
}


/* SPIN*/

.ant-spin-dot i {
  background-color: #1bbfbb !important;
}

.ant-spin-lg .ant-spin-dot {
  font-size: 42px !important;
  width: 42px !important;
  height: 42px !important;
}

.ant-spin {
  color: #1bbfbb !important;
}

.ant-spin-blur {
  opacity: 0.3 !important;
}

/* SELECT */

.ant-select.select-drop {
  margin-left: 10px;
}

.ant-select.select-drop > .ant-select-selection {
  border: none !important;
}

/* DROPDOW */

:root .ant-dropdown-trigger > .anticon:not(.anticon-ellipsis),
:root .ant-dropdown-link > .anticon:not(.anticon-ellipsis) {
  font-size: 16px !important;
}

/* ALERT */

.ant-alert-info {
  border: 1px solid #1bbfbb !important;
  background-color: #ddf6f6 !important;
}

/* BUTTON*/

.ant-btn-primary{
  background-color: #1bbfbb !important;
  border-color: #1bbfbb !important;
}

.ant-btn-primary.btn-custom-primary {
  background-color: #1bbfbb !important;
  border-color: #1bbfbb !important;
}

.ant-calendar-ok-btn{
  font-size: 14px;
  font-weight: 700 !important;
  letter-spacing: -0.3px;
  line-height: normal;
  padding: 6px 23px;
  border-radius: 15px !important;
  background-color: #36a2eb !important;
  border-color: #36a2eb !important;
  height: 32px !important;
    line-height: 32px !important;
}

.ant-btn-dashed:hover,
.ant-btn-dashed:focus {
  color: #1bbfbb !important;
  background-color: #fff !important;
  border-color: #1bbfbb !important;
}

.ant-btn:hover,
.ant-btn:focus {
  border-color: #1bbfbb !important;
}

.column-letter {
  text-transform: lowercase;
  display: block;
}

.column-letter::first-letter {
  text-transform: uppercase;
}

.first-letter{
  text-transform: lowercase;
  display: block;
}

.first-letter::first-letter{
  text-transform: uppercase;
}

.ant-list-item-meta-title{
  text-transform: lowercase;
}

.ant-list-item-meta-title::first-letter{
  text-transform: uppercase;
}

.institutions-skeleton, .ant-skeleton-active{
  width: 95% !important;
  
}

.ant-tooltip{
  z-index: 8888888 !important;
}

.table-procedure tr:nth-child(1) td:nth-child(4) {
  background: #1bbfbb;
  color: white;
  font-weight: 600;
}
.table-procedure tr:nth-child(2) td:nth-child(4) {
  background: #1bbfbba6;
  font-weight: 600;
}
.table-procedure tr:nth-child(3) td:nth-child(4) {
  background: #1bbfbb66;
  font-weight: 600;
}
.table-procedure tr:nth-child(4) td:nth-child(4) {
  background: #1bbfbb2e;
  font-weight: 600;
}
.table-procedure tr:nth-child(5) td:nth-child(4) {
  background: rgb(242, 252, 252);
  font-weight: 600;
}
.table-procedure td:nth-child(4) {
  text-align: center;
}

.table-turnover tr:nth-child(1) td:nth-child(3) {
  background: rgb(240, 115, 150);
  color: white;
  font-weight: 600;
}
.table-turnover tr:nth-child(2) td:nth-child(3) {
  background: rgb(240, 115, 150, 0.82);
  font-weight: 600;
}
.table-turnover tr:nth-child(3) td:nth-child(3) {
  background: rgb(240, 115, 150, 0.6);
  font-weight: 600;
}
.table-turnover tr:nth-child(4) td:nth-child(3) {
  background: rgb(240, 115, 150, 0.35);
  font-weight: 600;
}
.table-turnover tr:nth-child(5) td:nth-child(3) {
  background: rgb(240, 115, 150, 0.1);
  font-weight: 600;
}
.table-turnover td:nth-child(3) {
  text-align: center;
}
.table-turnover th:nth-child(3) {
  text-align: center;
}

.login-form-forgot {
  text-align: center !important;
}

h1.title {
  color: #343f5c;
  font-size: 28px !important;
  font-weight: bold !important;
  letter-spacing: 0.5px !important;
}

.card-indicator {
  text-align: center;
  border-radius: 8px;
  min-height: 200px;
}

.container-indicator {
  padding: "0px 15px 10px" !important;
}

.btn-details-all {
  border-radius: 15px !important;
  background-color: #ddf6f6 !important;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: -0.3px;
  text-align: center;
  color: #1bbfbb;
  border: 0.5px solid #ddf6f6 !important;
  font-weight: 500;
}

.btn-details-all:hover {
  border: 0.5px solid #1bbfbb !important;
}

.card-sub-title {
  font-weight: 500;
  text-align: center;
  background: white;
  font-size: 17px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.24;
  letter-spacing: 0.5px;
  text-align: center;
  color: #343f5c;
}

.card-chart .ant-card-head .ant-card-head-wrapper .ant-card-head-title {
  font-size: 14px !important;
}

.card-details {
  top: 10px;
  right: 10px;
  text-align: right;
  margin-left: auto;
  position: absolute;
  z-index: 1;
}

.card-info {
  top: 10px;
  left: 10px;
  text-align: right;
  margin-left: auto;
  position: absolute;
}

.btn-card-details {
  color: #b7bac4 !important;
}

.card-number span {
  font-size: 3.2vw;
  color: #1bbfbb !important;
  margin-top: 5px;
  height: 54px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: 2;
  letter-spacing: 3px;
  text-align: center;
}

.card-number small {
  font-size: 1.6vw;
  color: #1bbfbb !important;
  margin-top: 5px;
  height: 54px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: 2;
  letter-spacing: 3px;
  text-align: center;
}



.ant-message{
  z-index: 87787878787 !important;
}
/* CARD */

.ant-card {
  border-radius: 8px !important;
  box-shadow: 0 4px 14px 1px rgba(0, 0, 0, 0.04) !important;
}
.ant-card-head {
  border-bottom: none;
}
.ant-card-head-title {
  font-size: 17px !important;
  letter-spacing: 0.5px !important;
  color: #343f5c !important;
  font-weight: 600 !important;
  text-overflow: initial !important; 
   overflow: auto !important; 
   white-space: normal  !important; 
}

.ant-card-head-title small {
  font-size: 10px !important;
  font-weight: 400 !important;
}

.ant-card-body {
  padding: 20px 24px;
}

.ant-card-bordered {
  border: none !important;
}

::selection {
  background: #1bbfbb !important;
}

.container-logo {
  display: inline-block !important;
  /*box-shadow: 0px 0px 14px 1px rgba(0, 0, 0, 0.04) !important;*/
  margin-right: 10px;
}

.container-logo img {
  width: 270px !important;
  margin-bottom: 5px;
}

.container-logo-mobile img {
  width: 60px !important;
  margin-bottom: 5px;
}

.container-menu-user {
  display: inline-block !important;
  /*box-shadow: -3px 0px 14px 0px rgba(0, 0, 0, 0.04) !important;*/
  margin-left: 10px;
  padding-left: 15px;
}

.ant-modal-body {
  max-height: calc(100vh - 235px) !important;
  overflow: auto;
}

.ant-modal-header {
  border: none !important;
}

.ant-modal-title {
  font-size: 17px !important;
  letter-spacing: 0.5px !important;
  color: #343f5c !important;
  font-weight: 600 !important;
}

.ant-tag {
  height: 32px !important;
  line-height: 32px !important;
  border-radius: 15px !important;
}

.rowContainer {
  padding-top: 10px;
}

.ant-btn.ant-btn-primary.christmas:before {
  display: none !important;
}

.total-procedure {
  font-size: 14px !important;
  letter-spacing: 0.5px !important;
  color: #343f5c !important;
  font-weight: 600 !important;
}

.text-indicator-room {
  text-align: right;
  font-size: 14px !important;
  letter-spacing: 0.5px !important;
  color: #343f5c !important;
  font-weight: 600 !important;
}

/* TIME PICKER */

.ant-time-picker {
  width: 72px !important;
}

.alert-custom {
  border: none !important;
  background-color: transparent !important;
}

.alert-custom.ant-alert-with-description .ant-alert-icon {
  left: 4px;
  font-size: 44px;
}

.ant-layout-sider-below {
  box-shadow: -4px 0 20px -5px black !important;
}

.margin-content {
  padding: 80px 20px 20px 220px !important;
}

.menu-content {
  position: fixed !important;
  z-index: 1023 !important;
  min-height: 100vh !important;
  background: #fff !important;
  padding: 62px 0 0 0 !important;
}

.header-mobile {
  display: none !important;
}

.header-desktop {
  display: inline-block !important;
}

.login-mod-name {
  display: inline-block;
}

.ant-layout-sider-zero-width-trigger {
  top: 8px !important;
  background: #1cc7c3 !important;
  z-index: 1;
}

.container-modal {
  min-width: 70% !important;
}

.container-modal-med{
  min-width: 50% !important;
}

.col-padding {
  padding-top: 8px !important;
  padding-bottom: 8px !important;
}

.btn-dropdow {
  border: none !important;
}

.btn-dropdow span {
  font-size: 15px;
  font-weight: 600;
}


.ant-statistic-content-prefix{
  display: inline-flex !important;
}


.ant-dropdown:before {
  position: absolute;
  top: -7px;
  left: -7px;
  right: -7px;
  bottom: -7px;
  content: " ";
  opacity: 0.0001;
}

.dropdown-institution-menu .ant-dropdown-menu-item > a,
.ant-dropdown-menu-submenu-title > .logout a {
  font-weight: 500 !important;
  color: #c1456b !important;
}

.dropdown-institution-menu
  .ant-list-item-meta
  .ant-list-item-meta-description
  small {
  font-size: 14px !important;
  font-weight: 500 !important;
  color: #7c8595 !important;
}

.find-menu-container {
  border: 1px solid #e8e8e8;
  border-radius: 4px;
  padding: 8px 8px;
  height: 300px;
  overflow: auto;
}

.find-menu-container
  .ant-list-item-meta
  .ant-list-item-meta-content
  .ant-list-item-meta-title {
  font-size: 15px !important;
  font-weight: 600 !important;
  line-height: 1.47 !important;
  letter-spacing: -0.4px !important;
  color: #343f5c !important;
  margin-top: 8px;
}



.ant-dropdown-menu-item:hover, .ant-dropdown-menu-submenu-title:hover{
  background: #ddf6f6
}

.find-menu-container .ant-divider-horizontal {
  margin: 8px 0;
}

.find-menu-container .ant-list-item-meta {
  background-color: #fff;
  cursor: pointer;
}

.find-menu-container .ant-list-item-meta:hover {
  border-right: 8px solid #1bbfbb !important;
}

.ant-dropdown-menu-item-disabled,
.ant-dropdown-menu-item-disabled:hover {
  background-color: #fff;
  cursor: default;
}

.dropdown-institution-menu .ant-list-item-meta .ant-list-item-meta-title {
  font-size: 15px !important;
  font-weight: 600 !important;
  line-height: 1.47 !important;
  letter-spacing: -0.4px !important;
  color: #343f5c !important;
}

.institution-logo {
  width: 38px;
  height: 38px;
  line-height: 34px;
  border-radius: 10px;
  border: 2px solid #e0e3ea;
}

.institution-user {
  line-height: normal;
  align-items: center;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.institution-selected {
  cursor: pointer;
  font-size: 16px;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  letter-spacing: -0.4px;
  color: #343f5c;
  max-width: 315px;
  float: right;
  width: "100%";
  max-width: "300px" 
}
.text-institution {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.institution-user small {
  font-size: 14px;
  color: #7c8595;
}

.filter-date {
  float: right;
}

.header-component {
  position: fixed;
  z-index: 1024;
  width: 100%;
  padding: 0 17px;
}

.login-page .logo-axreg {
  width: 100%;
  max-width: 302px;
}

.col-filter-date {
  text-align: right !important;
}

.ant-btn-primary:not(.ant-btn-circle),
.ant-btn-secondary:not(.ant-btn-circle) {
  font-size: 14px;
  font-weight: 700;
  letter-spacing: -0.3px;
  line-height: normal;
  padding: 6px 23px;
  border-radius: 15px;
}

.select-institution{
  width: 300px !important;
  display: inline-block;
  margin-right: 10px;
}

.select-institution-mobile{
  width: 100% !important;
  display: none
}


.flag-legend {
      border: 1px solid red !important;
      color: red !important;
}

.flag-legend:hover {
      border: 1px solid red !important;
      color: red !important;
}



#ChartContainer{
  height:100%;
  width:100%;
  position:absolute;
}

@media (min-width: 1200px) {
  .institution-selected,
  .institution-user {
    display: flex;
  }
}

/* Device Table Ipad */
@media (max-width: 1200px) {
  .card-number span {
    font-size: 5vw !important;
  }

  .card-number small {
    font-size: 2vw !important;
  }

  .margin-content {
    padding: 115px 20px 20px 30px !important;
  }

  .menu-content {
    padding: 10px 0 0 0 !important;
  }

  .ant-layout-header {
    z-index: 2 !important;
  }

  .header-mobile {
    display: inline-block !important;
  }

  .header-desktop {
    display: none !important;
  }

  .container-modal {
    min-width: 70% !important;
  }

  .container-modal-med{
    min-width: 50% !important;
  }

  .ant-layout-sider-zero-width-trigger {
    top: 64px !important;
    background: #1cc7c3 !important;
    z-index: 1;
  }
}

/* Device Table galaxy tab */
@media (max-width: 992px) {
  .card-number span {
    font-size: 5.5vw !important;
  }

  .card-number small {
    font-size: 2.2vw !important;
  }
}

/* Device Smartphone galaxy note */
@media (max-width: 768px) {
  
  .ant-statistic-content-prefix{
    display: block!important;
  }

  
  .ant-card-extra{
    display: none;
  }
  .card-number span {
    font-size: 7.5vw !important;
  }

  .card-number small {
    font-size: 3vw !important;
  }

  .login-mod-name {
    display: none;
  }

  .header-mobile {
    display: inline-block !important;
  }

  .header-desktop {
    display: none !important;
  }

  .container-modal {
    min-width: 90% !important;
  }

  .ant-calendar-range-picker-input {
    width: 40% !important;
  }

  .select-institution-mobile{
    width: 100% !important;
    display: inline-block
  }

  .select-institution{
    width: 300px !important;
    display: none
  }

  .container-select-mobile{
    width: 100% !important;
  }
  
}

/* Device Smartphone */
@media (max-width: 576px) {
  .btn-popover-interval{
    display: block !important;
  }
  
  .card-number span {
    font-size: 9.5vw !important;
  }

  .card-number small {
    font-size: 3.5vw !important;
  }
  .tag-days {
    display: none !important;
  }
  .col-filter-date {
    text-align: center !important;
  }

  .ant-calendar-picker-icon {
    display: none !important;
  }

  .ant-calendar-picker-input {
    width: 80% !important;
  }

  .header-component {
    padding: 0 5px;
  }
  

}

/* Device Smartphone */
@media (max-width: 375px) {
  .card-number span {
    font-size: 10vw !important;
  }

  .card-number small {
    font-size: 4vw !important;
  }
}
